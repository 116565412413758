ion-modal.trade-details-modal {
    --height: 45%;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal.trade-details-modal::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
}

ion-modal.trade-details-modal ion-toolbar {
    --background: rgb(14 116 144);
    --color: white;
}