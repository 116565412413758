ion-datetime {
    /* --background: #fff1f2;
    --background-rgb: 255, 241, 242; */

    border-radius: 16px;
    /*box-shadow: rgba(var(--ion-color-rose-rgb), 0.3) 0px 10px 15px -3px;*/
    margin: 16px auto auto;
}

.datetime-header {
    padding: 12px;
    background: var(--ion-color-base);
    /*color: var(--title-color);*/
}



.ion-datetime-button-overlay ion-datetime.datetime-grid {
    margin-top: 0;
    border-radius: 4px;
}

.datetime-header .datetime-selected-date {
    margin-top: 0 !important;
}

.tradeDay .datetime-header {
    margin-top: 0 !important;
    font-size: 18px;
}

.tradeDay .datetime-selected-date {
    margin-top: 0 !important;
}

.negative {
    color: #f4623a;
}

.positive {
    color: lightgreen;
}