.ion-page {
  max-width: 980px;
  margin: auto;
}

.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

.profile-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.in-toolbar .item > div{
  border: none;
}

ion-segment {
  /* --background: #54dc98; */
}

@media all and (display-mode: standalone) {
  .pwaInstallation {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
}

.table_head {
  font-size: 1.05em;
  font-weight: bold;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.table_head ion-col {
  background-color: var(--ion-color-primary);
}

.data-row:nth-child(even) ion-col {background: var(--ion-color-step-100)}
.data-row:nth-child(odd) ion-col {background: var(--ion-color-step-50)}

/* Main-menu */
ion-menu::part(backdrop) {
  background-color: rgba(255, 0, 255, 0.5);
}

ion-menu::part(container) {
  border-radius: 0 20px 20px 0;

  box-shadow: 4px 0px 16px rgba(255, 0, 255, 0.18);
}

.sideBarContent {
  background-color: transparent;
}
.sideBarContent .item {
  --background: var(--ion-color-step-150) !important
}