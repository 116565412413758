form {
    max-width: 600px;
    text-align: center;
    margin: 20px auto;
}

input,
textarea {
    border: 0;
    outline: 0;
    padding: 1em;
    border-radius: 4px;
    display: block;
    width: 100%;
    margin-top: 1em;
    font-family: 'Merriweather', sans-serif;
    resize: none;
}

#input-submit {
    color: #e0e0e0;
    background: var(--ion-item-background);
    cursor: pointer;
}

#input-submit:hover {
    box-shadow: 0 3px 3px 3px rgba(0, 0, 0, 0.2);
    color: var(--color-selected);
}

textarea {
    height: 126px;
}

.half {
    float: left;
    width: 48%;
    margin-bottom: 1em;
}

.right {
    width: 50%;
}

.left {
    margin-right: 2%;
}

@media (max-width: 480px) {
    .half {
        width: 100%;
        float: none;
        margin-bottom: 0;
    }
}


.cf:before,
.cf:after {
    content: ' ';
    display: table;
}

.cf:after {
    clear: both;
}

.customToast {
    margin-top: 4rem;
}