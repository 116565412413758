
.account-popover {
    /* background-color: transparent; */
    --width: auto;
}

.account-popover .popover-viewport {
    --offset-y: 20px;
}

.account-popover .alert-wrapper {
    min-width: 75%;
}

.headerToolbar ion-select {
    width: auto;
    max-width: 400px !important;
    margin-top: 4px;
}

.headerToolbar {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}