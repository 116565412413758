ion-col {
    background-color: var(--ion-color-step-50);
    border: solid 0px var(--ion-color-step-600);
    color: var(--ion-color-step-600);
    text-align: right;
}

ion-row:first-child ion-col {
    text-align: center;
}

.arrow_box {
    padding: 6px;
}

.arrow_box h3 {
    font-weight: bold;
    background-color: var(--ion-color-secondary);
    color: var(--ion-color-primary-contrast);
    padding: 4px;
}

.negativeAmount {
    color: rgb(244, 98, 58);
}

.chart-label {
    color: lightgray !important;
}

.rangeSelector, .portfolioSelector {
    position: sticky;
    top: 0;
    z-index: +1;
    background-color: var(--ion-background-color);
}

